<template>
  <div>
    <b-row>
      <b-col lg="6">    
        <FindObject render="search"
                    tag="Cuenta Contable de Pasivo"
                    type="accountingAccountsImputation" 
                    @select-object="loadAccountingAccounts($event)" 
                    :valueID="crud.form.accounting_accounts_id"
                    :where="conditionAccountingAccountsFilters"
                    :key="componentKey"/>
      </b-col>
    </b-row>
    
    <b-row>
      <b-col lg="12">    
        <hr>
      </b-col>
    </b-row>
    
    <b-row>
      <b-col lg="4" class="mb-3">
        <b-form-select v-model="crud.form.accounting_accounts_secondary_type" :options="arr.select.options" size="sm" @input="changeTypeAccountSecondary()"></b-form-select>
      </b-col>
    </b-row>

    <b-row v-if="crud.form.accounting_accounts_secondary_type=='default'">
      <b-col lg="6">
        <FindObject render="search"
                    tag="Cuenta Contable de Gasto"
                    type="accountingAccountsImputation" 
                    @select-object="loadAccountingAccountsSecondary($event)" 
                    :valueID="crud.form.accounting_accounts_secondary_id"
                    :where="conditionAccountingAccountsFiltersSecondary"
                    :key="componentKey"/>
      </b-col>
    </b-row>
    <b-row v-if="crud.form.accounting_accounts_secondary_type=='multiple'">
      <b-col lg="12">
        <b-row>
          <b-col lg="4">
            <b-form-group label="Alias">
                <b-form-input type="text"
                            size="sm"
                            v-model="crud.concepts.alias">
                </b-form-input>           
            </b-form-group>
          </b-col>          
          <b-col lg="7">
            <FindObject render="search"
                        tag="Cuenta Contable de Gasto"
                        type="accountingAccountsImputation" 
                        @select-object="loadAccountingAccountsMultiple($event)" 
                        :valueID="crud.concepts.accounting_accounts_id"
                        :where="conditionAccountingAccountsFiltersSecondary"
                        :key="componentKey" />
          </b-col>
          <b-col lg="1">
            <b-button variant="success" class="pull-right suppliers-accounting-account-add-concepts" size="sm" @click="addConcept()" title="Agregar Cuenta">
              <i class="fa fa-plus"></i>
            </b-button>
          </b-col>                    
        </b-row>
      </b-col>  
      <b-col lg="12">
        <b-table class="mb-0"
              style="min-height:130px;"
              responsive
              head-variant="dark"
              :hover="true"
              :small="true"
              :fixed="true"
              :items="table.items"
              :fields="table.fields"                            
              :busy="table.isBusy"                     
              v-if="table.items.length || table.isBusy">   
            
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"                    
              :key="field.key"
              :style="{ width: field.width }"
            >
          </template> 

          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Cargando...</strong>
            </div>
          </template>           

          <template v-slot:cell(accounting_accounts_id)="data">
            {{data.item.accounting_accounts.name}}
          </template>                         
                        
          <template v-slot:cell(f_action)="data">
            <b-dropdown right text="" size="sm" variant="outline-dark" class="pull-right">
              
              <b-dropdown-header>Acciones</b-dropdown-header>
              <b-dropdown-item @click="removeConcept(data.index)">
                <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
              </b-dropdown-item>                     

            </b-dropdown>
          </template>
        </b-table>
        <b-alert show variant="warning" v-else>
          No hay productos para mostrar
        </b-alert>           
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col lg="12">            
        <b-button variant="dark" class="pull-right" size="sm" @click="save()">Guardar</b-button>
      </b-col>    
    </b-row>
  </div>
</template>
<script>
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'    
  import FindObject from '@/components/inc/find/findObject'  

  export default {
    components: {
      FindObject,        
    },        
    props: [
      'suppliers',
      'suppliersID',
    ],
    data() {
      return {
        table : {
          items: [],
          fields: [                       
            {key: 'alias', label: 'Alias', class:'text-left align-middle', width:"20%"},
            {key: 'accounting_accounts_id', label: 'Cuenta Contable', class:'text-left align-middle', width:"70%"},                        
            {key: 'f_action', label:'', class:'text-right align-middle', width:"10%"},
          ],          
          isBusy: false,          
        },           
        crud: {
          form: {
            id: 0,
            suppliers_id: 0,
            accounting_accounts: null,
            accounting_accounts_id: 0,
            accounting_accounts_secondary_type: 'default',
            accounting_accounts_secondary: null,
            accounting_accounts_secondary_id: 0,            
            accounting_accounts_secondary_concepts: '',
          },
          concepts: {
            alias: '',
            accounting_accounts: null,
            accounting_accounts_id: 0,
            details: [],
          },
        },
        arr: {
          select: {
            options: [              
              { value: 'default', text: 'Solo una cuenta de gasto por defecto' },
              { value: 'multiple', text: 'Multiples cuentas de gastos' },              
            ]            
          }
        },
        componentKey: 0,
      }
    },
    computed: {
      // CONDITIONS SELECT
      conditionAccountingAccountsFilters(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Pasivo'}
        ];
      },  
      conditionAccountingAccountsFiltersSecondary(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Egresos'}
        ];
      },  
    },
    watch: {        
      suppliers: function (newQuestion, oldQuestion) {
        this.load()
      }
    },        
    methods: {
      load() {        
        if(this.suppliers.accounting_account){
          
          this.crud.form.id = this.suppliers.accounting_account.id,
          this.crud.form.suppliers_id = this.suppliers.accounting_account.suppliers_id,
                                              
          this.crud.form.accounting_accounts = this.suppliers.accounting_account.accounting_account
          this.crud.form.accounting_accounts_id = this.suppliers.accounting_account.accounting_accounts_id     

          if(this.suppliers.accounting_account.accounting_account_secondary) {
            this.crud.form.accounting_accounts_secondary_type = 'default'
            this.crud.form.accounting_accounts_secondary = this.suppliers.accounting_account.accounting_account_secondary
            this.crud.form.accounting_accounts_secondary_id = this.suppliers.accounting_account.accounting_accounts_secondary_id     
          } else {
            this.crud.form.accounting_accounts_secondary_type = 'multiple'
            this.crud.form.concepts = this.suppliers.accounting_account.concepts
            
            if(this.crud.form.concepts) {
              this.crud.concepts.details = this.crud.form.concepts
            } else {
              this.crud.concepts.details = []
            }

            this.table.isBusy = true

            if(this.crud.concepts.details) {
              this.table.items = this.crud.concepts.details
              this.crud.form.accounting_accounts_secondary_concepts = JSON.stringify(this.crud.concepts.details)
            } else {
              this.table.items = []
              this.crud.form.accounting_accounts_secondary_concepts = ''
            }            

            this.table.isBusy = false
          }
          
          this.forceRerender()        
        } else {
          this.crud.form.suppliers_id = this.suppliersID                    
        }
      },
            
      save() {
        if(!this.crud.form.accounting_accounts_id && !this.crud.form.accounting_accounts_secondary_id && !this.crud.form.accounting_accounts_secondary_concepts && !this.crud.form.id){
          this.$awn.alert("Seleccione al menos una cuenta para guardar los cambios");
          return false
        }

        var typeOperation = ''
        let loader = this.$loading.show();                

        if(!this.crud.form.accounting_accounts_id && !this.crud.form.accounting_accounts_secondary_id && !this.crud.form.accounting_accounts_secondary_concepts){
          typeOperation = 'delete'
          var result = serviceAPI.eliminarCuentaContable(this.crud.form.id);
        } else {        
          if(this.crud.form.id) {
            typeOperation = 'edit'
            var result = serviceAPI.editarCuentaContable(this.crud.form);
          } else {
            typeOperation = 'save'
            var result = serviceAPI.agregarCuentaContable(this.crud.form);
          }                    
        }                

        result.then((response) => {                    
          loader.hide()
          this.$awn.success("Datos guardados con éxito");

          if(typeOperation != 'delete') {
            this.crud.form.id = response.data.id
          } else {
            this.crud.form.id = 0
          }
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      changeTypeAccountSecondary() {
        if(this.crud.form.accounting_accounts_secondary_type == 'default') {
          this.crud.form.accounting_accounts_secondary_concepts = ''
        }

        if(this.crud.form.accounting_accounts_secondary_type == 'multiple') {
          this.crud.form.accounting_accounts_secondary = null
          this.crud.form.accounting_accounts_secondary_id = 0                                    
        }       
      },

      loadAccountingAccounts (object) {
        if(object){
          this.crud.form.accounting_accounts = object
          this.crud.form.accounting_accounts_id = object.id             
        } else {
          this.crud.form.accounting_accounts = null
          this.crud.form.accounting_accounts_id = 0
        }
      },
      loadAccountingAccountsSecondary (object) {
        if(object){
          this.crud.form.accounting_accounts_secondary = object
          this.crud.form.accounting_accounts_secondary_id = object.id             
        } else {
          this.crud.form.accounting_accounts_secondary = null
          this.crud.form.accounting_accounts_secondary_id = 0
        }
      },    
      forceRerender() {
        this.componentKey += 1;  
      },

      loadAccountingAccountsMultiple (object) {
        if(object){
          this.crud.concepts.accounting_accounts = object
          this.crud.concepts.accounting_accounts_id = object.id             
        } else {
          this.crud.concepts.accounting_accounts = null
          this.crud.concepts.accounting_accounts_id = 0
        }
      },
      addConcept() {               
        // valido si ya fue agregada la cuenta contable
          var status = false
          var alias = ''
          this.crud.concepts.details.forEach(element => {
            if(element.accounting_accounts_id == this.crud.concepts.accounting_accounts_id) {
              status = true
              alias = element.alias
            }
          });        

          if(status) {
            this.$awn.alert("La cuenta contable ya fue agregada con el alias '" + alias + "'");
            return false
          }
        // fin
        
        this.table.isBusy = true        
          this.crud.concepts.details.push({
            alias: this.crud.concepts.alias,
            accounting_accounts: this.crud.concepts.accounting_accounts,
            accounting_accounts_id: this.crud.concepts.accounting_accounts_id,
          })

          this.crud.concepts.alias = ''
          this.crud.concepts.accounting_accounts = null
          this.crud.concepts.accounting_accounts_id = 0
          this.forceRerender()        
          
          if(this.crud.concepts.details) {
            this.table.items = this.crud.concepts.details
          } else {
            this.table.items = null
          }
            
          this.crud.form.accounting_accounts_secondary_concepts = JSON.stringify(this.crud.concepts.details)
        this.table.isBusy = false
      },
      removeConcept(index) {
        this.crud.concepts.details.splice(index, 1)
        this.crud.form.accounting_accounts_secondary_concepts = JSON.stringify(this.crud.concepts.details)
      },
    }
  }
</script>
<style scoped>
  .suppliers-accounting-account-add-concepts {
    margin-top: 30px;
  }
</style>